import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import backgroundImage from "../../images/backgrounds/home-hero-bg-new.jpg"

const HeroHomeUpdateVersion = () => {
  const data = useStaticQuery(graphql`
    query HeroHomeUpdateVersionQueryNew {
      prismicHomePage {
        data {
          hero_subtitle
          button_name_1
          button_name_1_hover
          button_link_1 {
            uid
          }
          button_name_2
          button_link_2 {
            uid
          }
          hero_logo {
            gatsbyImageData(
              width: 307
            )
          }
          hero_background_image {
            gatsbyImageData(
              width: 1920
              height: 860
              layout: FULL_WIDTH
              placeholder: NONE
            )
          }
        }
      }
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg-new.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 860
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
    }
  `)

  const doc = data.prismicHomePage
  const heroLogo = getImage(doc.data.hero_logo)
  const heroBgImage = getImage(doc.data.hero_background_image)
  const heroBgPlaceholder = getImage(data.imgDefault)

  // NOTE: Make sure to upload the home-hero-bg-new.jpg and replace the old one in Prismic Home Page

  return (
    <>
      <div className="hidden md:grid relative bg-site-gray-new md:pt-14 xl:pt-20">
        <StaticImage
          style={{
            gridArea: "1/1",
            opacity: "0.5",
            width: "auto",
            height: "800px",
            objectFit: "cover",
          }}
          src={"../../images/backgrounds/home-hero-bg-new.jpg"}
          width={1920}
          height={800}
          formats={["WEBP"]}
          alt="home hero background"
          loading="eager"
          placeholder="none"
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 -mt-12">
            <div className="flex justify-center">
              <StaticImage
                src="../../images/logos/SRA-logo-hero.png"
                width={307}
                heigth={143}
                quality={95}
                formats={["WEBP"]}
                alt="SRA 831(b) ADMIN logo"
                objectFit="contain"
                className="w-full mx-auto"
                loading="eager"
                placeholder="none"
              />
            </div>

            <h2 className="text-white text-6xl font-black text-center uppercase mt-4">
              Weather The Storm<sup>&reg;</sup>
            </h2>

            <h3 className="max-w-xl text-white text-xl md:text-2xl font-bold text-center mt-6">
              {doc.data.hero_subtitle}
            </h3>
            <div className="flex flex-col items-center justify-center mt-6">
              <div className="hidden xl:flex flex-col items-center justify-center">
                <Link to={doc.data.button_link_1.uid ? `/${doc.data.button_link_1.uid}` : "/"}>
                  <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3 mb-6">
                    <span className="default-text mx-2">{doc.data.button_name_1}</span>
                    <span className="hover-text">{doc.data.button_name_1_hover}</span>
                  </button>
                </Link>
              </div>

              <div className="block xl:hidden mb-6">
                <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                <Link to="/qualify">
                  <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                    {doc.data.button_name_1_hover}
                  </button>
                </Link>
              </div>

              <Link to={doc.data.button_link_2.uid ? `/${doc.data.button_link_2.uid}` : "/"}>
                <button id="testimonials-button" className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-9 py-3">
                  {doc.data.button_name_2}
                </button>
              </Link>
            </div>            
          </div>
        </div>
      </div>
    </>
  )
}

export const onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([    
    <script
      key="custom-script"
      dangerouslySetInnerHTML={{
        __html: `
          window.addEventListener('load', (event) => {
            document.getElementById('qualify-button').addEventListener('click', function() {
              window.cg_conversion = true;
            });

            document.getElementById('testimonials-button').addEventListener('click', function() {
              window.cg_conversion = true;
            });
          });
        `,
      }}
    />,
  ]);
};

export default HeroHomeUpdateVersion
